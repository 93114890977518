import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Hidden } from '@mui/material';
import { VideoBackground } from './components/VideoBackground';
import { HeroContent } from './components/HeroContent/HeroContent';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

const MAX_WIDTH = 1508;

export const Hero = () => {

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <VideoBackground videoSourceName="apiPlatform.mp4">
        <Box
          maxWidth={MAX_WIDTH}
          marginX="auto"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          className={classes.overlaidContent}
        >
          <Typography gutterBottom variant="h2" component='h1'>
            {t('eapi-swissre-text')} {' '}
            <Hidden smUp><br/></Hidden>
            {t('eapi-developer-portal-text')}
          </Typography>
          <Typography variant="h3" component='h2'>{t('eapi-hero-info')}</Typography>
        </Box>
      </VideoBackground>
      <Box maxWidth={MAX_WIDTH} marginX="auto">
        <HeroContent />
      </Box>
    </>
  );
};
