import React, { forwardRef, memo, useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { UseFormGetValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Grid, Typography } from '@material-ui/core';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { APIS } from '../../../../../constants/apis';
import { FieldNames, FieldLabels, SignUpFormData } from '../../constants';
import { useStyles } from './styles';

interface SummaryProps {
  getValues: UseFormGetValues<SignUpFormData>;
}

export const Summary = memo(
  forwardRef<ReCAPTCHA, SummaryProps>(({ getValues }, ref) => {
    const classes = useStyles();
    const configApi = useApi(configApiRef);
    const { t } = useTranslation();

    const reCaptchaSiteKey = configApi.getConfig('swissre.google').getString('reCaptchaSiteKey');

    const getSelectedApis = useCallback(() => {
      const apis = APIS.filter(({ id }) => getValues()[FieldNames.APIS][id]);

      return apis.length
        ? apis.map(({ id, name }) => <Chip key={id} variant="outlined" color="primary" label={name} />)
        : t('eapi-signup-no-apis-selected-text');
    }, [APIS, getValues, FieldNames.APIS]);

    return (
      <Box textAlign="start">
        <Grid container spacing={4} className={classes.summaryData}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" gutterBottom>
              {t(FieldLabels.FIRST_NAME)}
            </Typography>
            <Typography variant="body2">{getValues()[FieldNames.FIRST_NAME]}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" gutterBottom>
              {t(FieldLabels.LAST_NAME)}
            </Typography>
            <Typography variant="body2">{getValues()[FieldNames.LAST_NAME]}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              {t(FieldLabels.BUSINESS_EMAIL)}
            </Typography>
            <Typography variant="body2">{getValues()[FieldNames.BUSINESS_EMAIL]}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">API(s)</Typography>
            <Box mt={1} display="flex" flexWrap="wrap" gridGap={8} gridColumnGap={8}>
              {getSelectedApis()}
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {t('eapi-signup-agreement-text')}
        </Typography>
        <ReCAPTCHA sitekey={reCaptchaSiteKey} ref={ref} size="invisible" />
      </Box>
    );
  }),
);

Summary.displayName = 'Summary';
