// extended apis for scaffolder related calls

import { createApiRef, DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { APIsStats, EmailValidator } from '@internal/plugin-eapi-common';
import { FileType } from '../../types';


interface APIResponse {
    status: number;
    values?: Array<string>;
    isValidEmail?: boolean;
    errorMessage?: string;
}

interface UserDataResponse {
    status: number;
    displayName: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
}

interface MDMApiInterface {
    fetchAPMIDs(apmId: string): Promise<APIResponse>;
}

interface AADApiInterface {
    validateEmail(email: string): Promise<APIResponse>;
    fetchUserDataByEmail(email: string): Promise<UserDataResponse>;
}

export interface EAPIAssetsApiInterface {
  fetchFile(containerName: string, filePath: string, fileType: FileType): Promise<Blob>;
}

export interface ApiAnalyticsInterface {
    fetchStats(): Promise<APIsStats>
}

export const mdmApiRef = createApiRef<MDMApiInterface>({
    id: 'plugin.mdm'
});

export const aadApiRef = createApiRef<AADApiInterface>({
    id: 'plugin.aad'
});

export const eapiAssetsApiRef = createApiRef<EAPIAssetsApiInterface>({
  id: 'plugin.eapi-assets'
});

export const apiAnalyticsRef = createApiRef<ApiAnalyticsInterface>({
    id: 'plugin.api-analytics'
  });

type ApiOptions = {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
};

export class MDMapi implements MDMApiInterface {
    private readonly discoveryApi: DiscoveryApi;
    private readonly fetchApi: FetchApi;

    constructor(options: ApiOptions) {
        this.discoveryApi = options.discoveryApi;
        this.fetchApi = options.fetchApi;
    }

    async fetchAPMIDs(apmId: string) {
        const mdmApiUrl = await this.discoveryApi.getBaseUrl('mdm');
        const url = `${mdmApiUrl}/validate-id?apmID=${apmId}`;
        const response = await this.fetchApi.fetch(url)
            .then(d => d.json())
            .catch(error =>
                console.error("fetchAPMIDs error:", error)
            );
        return response;
    }
}

export class AADapi implements AADApiInterface {
    private readonly discoveryApi: DiscoveryApi;
    private readonly fetchApi: FetchApi;
    private emailValidator = new EmailValidator();

    constructor(options: ApiOptions) {
        this.discoveryApi = options.discoveryApi;
        this.fetchApi = options.fetchApi;
    }

    async validateEmail(email: string): Promise<APIResponse> {
        const isValid = this.emailValidator.isValidSwissReEmail(email);

        if (!isValid) return { status: 400, isValidEmail: false, errorMessage: 'The email address is not valid' };

        const aadApiUrl = await this.discoveryApi.getBaseUrl('aad');
        const url = `${aadApiUrl}/validate-email?emailAddress=${email}`;
        const response = await this.fetchApi.fetch(url)
            .then(d => d.json())
            .catch(error =>
                console.error("validateEmail error:", error)
            );

        return response;
    }

    async fetchUserDataByEmail(email: string) {

        const isValid = this.emailValidator.isValidSwissReEmail(email);

        if (!isValid) return null;

        const aadApiUrl = await this.discoveryApi.getBaseUrl('aad');
        const url = `${aadApiUrl}/user-details?emailAddress=${email}`;
        const response = await this.fetchApi.fetch(url)
            .then(d => d.json())
            .catch(error =>
                console.error("fetchUserDataByEmail error:", error)
            );

        return response;
    }
}

export class EAPIAssetsApi implements EAPIAssetsApiInterface {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: ApiOptions) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async fetchFile(containerName: string, filePath: string, fileType: FileType) {
    const fileExtension = fileType === 'image' ? filePath.split('.').pop() : 'zip';
    const contentType = fileType === 'image' ? `image/${fileExtension}` : `application/${fileExtension}`;

    try {
      const url = await this.discoveryApi.getBaseUrl(`eapi-assets/${containerName}/${filePath}`);
      const response = await this.fetchApi.fetch(decodeURIComponent(url), { method: 'GET', headers: { 'Content-Type': `${contentType}` } });
      if (!response.ok) {
        throw new Error(`Failed to fetch file, ${response.status} ${response.statusText}`);
      };

      return response.blob();
    } catch (error) {
      console.error('Get file call error :: ', error instanceof Error && error.message);
      throw error;
    }
  }
}

export class ApiAnalytics implements ApiAnalyticsInterface {
    private readonly discoveryApi: DiscoveryApi;
    private readonly fetchApi: FetchApi;
  
    constructor(options: ApiOptions) {
      this.discoveryApi = options.discoveryApi;
      this.fetchApi = options.fetchApi;
    }
  
    async fetchStats() {
      try {
        const url = await this.discoveryApi.getBaseUrl('api-analytics/apis');
        const response = await this.fetchApi.fetch(decodeURIComponent(url), { method: 'GET', headers: { 'Content-Type': 'application/json' } });
        if (!response.ok) {
          throw new Error(`Failed to fetch stats, ${response.status} ${response.statusText}`);
        };
  
        return response.json();
      } catch (error) {
        console.error('Get stats call error :: ', error instanceof Error && error.message);
        throw error;
      }
    }
  }