import React, { FC } from 'react';
import { Box, Link, Divider, Typography, Container } from "@material-ui/core";
import Linkedin from "../../components/Icons/Linkedin";
import Twitter from "../../components/Icons/Twitter";
import Youtube from "../../components/Icons/Youtube";
import Instagram from "../../components/Icons/Instagram";
import { ComplianceLinks, Links } from "../../constants/links";
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../hooks';
import { Language } from '@internal/plugin-eapi-common';
import { useStyles } from './styles';

export const Footer: FC = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  const { language } = useLanguage();

  if (!language) {
    return null;
  }

  return (
    <footer className={classes.footer}>
      <Container maxWidth="xl" className={classes.subFooter}>
        <Box className={classes.linksList}>
          <Link variant="body1" href={ComplianceLinks[language].PRIVACY_POLICY} target="_blank">{t('eapi-about-privacy-text')}</Link>
          <Link variant="body1" href={ComplianceLinks[language].TERMS_OF_USE} target="_blank">{t('eapi-terms-of-use-text')}</Link>
          {
            (language === Language.EN_US) 
              && <Link variant="body1" href={Links.SLAVERY_STATEMENT} target="_blank">{t('eapi-uk-slavery-stat')}</Link>
          }
        </Box>
        <Box className={classes.socialLinksList}>
          <Link className={classes.socialLink} href={Links.LINKEDIN} target="_blank" rel="noopener" aria-label='Visit our LinkedIn'>
            <Linkedin />
          </Link>
          <Link className={classes.socialLink} href={Links.TWITTER} target="_blank" rel="noopener" aria-label='Visit our Twitter'>
            <Twitter />
          </Link>
          <Link className={classes.socialLink} href={Links.YOUTUBE} target="_blank" rel="noopener" aria-label='Visit our Youtube'>
            <Youtube />
          </Link>
          <Link className={classes.socialLink} href={Links.INSTAGRAM} target="_blank" rel="noopener" aria-label='Visit our Instagram'>
            <Instagram />
          </Link>
        </Box>
      </Container>
      <Divider />
      <Container maxWidth="xl" className={classes.copyright}>
        <Typography variant="body1">&copy; {t('eapi-swissre-developer-portal-text', {currentYear: currentYear})}</Typography>
        <Typography variant="body1">{t('eapi-all-rights-reserved-text')}</Typography>
      </Container>
    </footer>
  );
}