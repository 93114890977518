import catnet from '../assets/apis/catnet.jpg';
import rds from '../assets/apis/risk-data-services.jpg';
import coiin from '../assets/apis/commercial-insurance-insights.jpg';
import ncme from '../assets/apis/nat-cat.jpg';
import pdsUs from '../assets/apis/property-data-services.jpg';
import natcatParametric from '../assets/apis/ncme.jpg';

export interface API {
  id: string;
  name: string;
  description: string;
  image: string;
  webpImage: string
  mobileImage?: string
}

export const APIS: ReadonlyArray<API> = [
  {
    id: 'catnet',
    name: 'CatNet® API',
    description: "eapi-apis-catnet-info",
    image: catnet,
    webpImage: "/apis/catnet-API-desktop.webp",
  },
  {
    id: 'rds',
    name: 'Risk, Data & Services for Corporates',
    description: 'eapi-apis-rds-info',
    image: rds,
    webpImage: "/apis/risk-data-services-API-desktop.webp",
  },
  {
    id: 'coiin',
    name: 'Commercial Insurance Insights',
    description: 'eapi-apis-coiin-info',
    image: coiin,
    webpImage: "/apis/commercial-insurance-insights-API-desktop.webp",
    mobileImage: "/apis/commercial-insurance-insights-API-mobile-img.webp",
  },
  {
    id: 'ncme',
    name: 'NatCat Modelling Engine (NCME)',
    description: 'eapi-apis-ncme-info',
    image: ncme,
    webpImage: '/apis/ncme-API-desktop.webp',
    mobileImage: '/apis/ncme-API-mobile-img.webp',
  },
  {
    id: 'pds-us',
    name: 'Property Data Service US',
    description: 'eapi-apis-pds-us-info',
    image: pdsUs,
    webpImage: "/apis/property-data-services-API-desktop.webp",
    mobileImage: "/apis/property-data-services-API-mobile-img.webp",
  },
  {
    id: 'natcat-parametric',
    name: 'NatCat Parametric',
    description: "eapi-apis-natcat-info",
    image: natcatParametric,
    webpImage: '/apis/nat-cat-API-desktop.webp',
    mobileImage: '/apis/nat-cat-API-mobile-img.webp',
  },
];
