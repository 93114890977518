import React, {FC, useEffect} from 'react';
import { useLocation, useNavigate  } from "react-router";
import {Box, Button, Typography, makeStyles} from "@material-ui/core";
import { getLocalizedRouteFromLanguage } from '@internal/plugin-eapi-react';
import {Routes} from "../../constants/routes";
import pageNotFoundImage from '../../assets/page-not-found.jpg';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../hooks';
import { PathLocales } from '@internal/plugin-eapi-react/src/constants/pathLocales';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.grey[300],
    backgroundImage: `linear-gradient(0deg, rgba(60, 65, 80, 0.65), rgba(60, 65, 80, 0.65)), url(${pageNotFoundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  button: {
    marginTop: theme.spacing(9),
  }
}))

export const PageNotFound: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== Routes.NOT_FOUND && pathname !== `/${PathLocales.PT_BR}/not-found`) {           
      navigate(getLocalizedRouteFromLanguage(Routes.NOT_FOUND, language), { replace: true });
    }
  }, [location, navigate, pathname, language]);

  return (
      <Box flex='1 0 auto' className={classes.wrapper}>
        <Typography variant="h6">{t('eapi-404-error')}</Typography>
        <Typography variant="h2">{t('eapi-page-not-found-text')}</Typography>
        <Button className={classes.button} variant="outlined" href={getLocalizedRouteFromLanguage(Routes.HOME, language)}>{t('eapi-page-not-found-info')}</Button>
      </Box>
  )
}