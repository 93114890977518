import { Box, makeStyles, Typography } from '@material-ui/core';
import { Avatar } from '@backstage/core-components';
import React from 'react';
import { Navigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getLocalizedRouteFromLanguage } from '@internal/plugin-eapi-react';
import { useAuth, useLanguage } from '../../hooks';
import { SupportMenu } from "./components/SupportMenu";
import { ProfileMenu } from "./components/ProfileMenu";
import { Routes } from '../../constants/routes';

const avatarCustomStyles = { width: 40, height: 40 }

const useStyles = makeStyles({
  parent: {
    width: '100%',
    whiteSpace: 'nowrap'
  },
  user: {
    fontSize: "15px",
    fontWeight: 400
  }
})

export const Profile = () => {
  const { user } = useAuth();
  const classes = useStyles();
  const { t } = useTranslation();
  const { language } = useLanguage();
  
  if (!user) {
    return <Navigate to={getLocalizedRouteFromLanguage(Routes.HOME, language)} />
  }

  const userName = user.profile?.displayName?.split(' ')[0] || "Human"

  return (
    <Box display="flex" alignItems="center" gridGap={6}>
      <SupportMenu />
      <Avatar
        customStyles={avatarCustomStyles}
        displayName={userName}
        picture="user.profile?.picture"
      />
      <Typography className={classes.user}>{t('eapi-greeting-text', {userName: userName})}</Typography>
      <ProfileMenu />
    </Box>
  );
};