import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { getUserLocale, Language } from '@internal/plugin-eapi-common';
import { getLanguageFromPath } from '@internal/plugin-eapi-react';
import i18n from '../../../config/i18n';
import { useAuth } from '../useAuth';

interface LanguageContextProps {
  language: Language | undefined;
  setLanguage: (newLanguage: Language) => Promise<void>;
}

const LanguageContext = createContext<LanguageContextProps>({
  language: undefined,
  setLanguage: async () => {},
});

export const LanguageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [language, setLanguageState] = useState<Language>();
  const { user } = useAuth();
  const { pathname } = useLocation();

  const setLanguage = useCallback(
    async (newLanguage: Language) => {
      if (newLanguage === language) return;

      setLanguageState(newLanguage);
      await i18n.changeLanguage(newLanguage);
    },
    [language, setLanguageState],
  );

  useEffect(() => {
    const detectedLanguage = user?.oktaAccessToken ? getUserLocale(user.oktaAccessToken) : getLanguageFromPath(pathname);

    setLanguage(detectedLanguage);
  }, [pathname, user?.oktaAccessToken, setLanguage]);

  if (!language) return null;

  return <LanguageContext.Provider value={{ language, setLanguage }}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => useContext(LanguageContext);
