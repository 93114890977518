import React, { FC, PropsWithChildren, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useDevPortalApi } from '../../api';
import i18n from '../../../config/i18n';
import { useLanguage } from '../useLanguage';

interface TranslationProviderProps {
  onTranslationsLoaded: () => void;
}

export const TranslationProvider: FC<PropsWithChildren<TranslationProviderProps>> = ({ onTranslationsLoaded, children }) => {
  const devPortalApi = useDevPortalApi();
  const { language } = useLanguage();

  useEffect(() => {
    if (!language) return;

    const loadTranslations = async () => {
      try {
        const translations = await devPortalApi.getTranslations();

        if (translations) {
          i18n.addResourceBundle(language, 'translation', translations);
          await i18n.reloadResources();
        }
      } catch (error) {
        console.error('Error loading translations:', error);
      } finally {
        onTranslationsLoaded();
      }
    };

    loadTranslations();
  }, [language]);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
