import React, { FC, useState, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Step,
  StepLabel,
  Stepper,
  StepConnector,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Container,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDialog } from '../../../hooks';
import { SignUpForm } from '../SignUpForm';
import { STEPS } from '../constants';
import { useStyles } from './styles';


export const SignUpDialog: FC = memo(() => {
  const classes = useStyles();
  const { isOpen, handleClose } = useDialog();
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = useCallback(setActiveStep, [activeStep]);

  const handleDialogClose = useCallback(() => {
    handleClose();
    setActiveStep(0);
  } , []);

  return (
    <Dialog fullScreen open={isOpen} onClose={handleDialogClose} className={classes.dialog} aria-labelledby="signup-form-dialog-title">
      <DialogTitle disableTypography id="signup-form-dialog-title">
        <Typography variant="h2">{t('eapi-signup-dialog-welcome-text')}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleDialogClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Container maxWidth="md">
          <Stepper className={classes.stepper} alternativeLabel activeStep={activeStep} connector={<StepConnector />}>
            {STEPS.map(({ label }) => (
              <Step key={label}>
                <StepLabel>
                  <Typography className={classes.stepperLabelText} variant="overline">
                    {t(label)}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <SignUpForm step={activeStep} onDialogClose={handleDialogClose} onStepChange={handleStepChange} />
        </Container>
      </DialogContent>
    </Dialog>
  );
});

SignUpDialog.displayName = 'SignUpDialog';
