import React, { FC } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { FlatRoutes } from '@backstage/core-app-api';
import { getLocalizedRouteFromPathname } from '@internal/plugin-eapi-react';
import { Routes as BaseRoutes } from './constants/routes';
import { ProtectedRoute } from './components/ProtectedRoute';
import { Home } from './pages/Home';
import { Solutions } from './pages/Solutions';
import { Subscriptions } from './pages/Subscriptions';
import { SubscriptionData } from './pages/Subscriptions/components/SubscriptionData';
import { ApiAnalytics } from './pages/ApiAnalytics';
import { Guides } from './pages/Guides';
import { Guide } from './pages/Guide';
import { SwaggerInfo } from './pages/Swagger';
import { SolutionDetails } from './pages/SolutionDetails';
import { SignedOut } from './pages/SignedOut';
import { PageNotFound } from './pages/PageNotFound';

export const DevPortalRoutes: FC = () => {
  const { pathname } = useLocation();

  return (
    <FlatRoutes>
      <Route path='/' element={<Navigate to={BaseRoutes.HOME} />} />;
      <Route path={getLocalizedRouteFromPathname(BaseRoutes.HOME, pathname)} element={<Home />} />
      <Route path={getLocalizedRouteFromPathname(BaseRoutes.SIGNED_OUT, pathname)} element={<SignedOut />} />
      <Route path={getLocalizedRouteFromPathname(BaseRoutes.NOT_FOUND, pathname)} element={<PageNotFound />} />
      <Route
        path={BaseRoutes.SOLUTIONS}
        element={
          <ProtectedRoute>
            <Solutions />
          </ProtectedRoute>
        }
      />
      <Route
        path={BaseRoutes.SOLUTION}
        element={
          <ProtectedRoute>
            <SolutionDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path={BaseRoutes.SUBSCRIPTIONS}
        element={
          <ProtectedRoute>
            <Subscriptions />
          </ProtectedRoute>
        }
      >
        <Routes>
          <Route path={BaseRoutes.SUBSCRIPTION} element={<SubscriptionData />} />
        </Routes>
      </Route>
      <Route
        path={BaseRoutes.API_ANALYTICS}
        element={
          <ProtectedRoute>
            <ApiAnalytics />
          </ProtectedRoute>
        }
      />
      <Route
        path={BaseRoutes.GUIDES}
        element={
          <ProtectedRoute>
            <Guides />
          </ProtectedRoute>
        }
      />
      <Route
        path={BaseRoutes.GUIDE}
        element={
          <ProtectedRoute>
            <Guide />
          </ProtectedRoute>
        }
      />
      <Route
        path={BaseRoutes.SWAGGER}
        element={
          <ProtectedRoute>
            <SwaggerInfo />
          </ProtectedRoute>
        }
      />
    </FlatRoutes>
  );
};

DevPortalRoutes.displayName = 'DevPortalRoutes';
