interface Step {
  label: string;
  title: string;
}

export const STEPS: Readonly<Array<Step>> = [
  {
    label: 'eapi-signup-personal-details-label-text',
    title: 'eapi-signup-personal-details-title-text',
  },
  {
    label: 'eapi-signup-apis-label-text',
    title: 'eapi-signup-apis-title-text',
  },
  {
    label: 'eapi-signup-summary-label-text',
    title: 'eapi-signup-summary-title-text',
  },
];
