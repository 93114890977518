import React, { FC } from 'react';
import { Navigate, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { getLocalizedRouteFromPathname } from '@internal/plugin-eapi-react';
import { SignInButton } from '../../components/SignInButton';
import { Page } from '../../layout/Page';
import { useStyles } from './styles';
import { LogOutModeType } from '../../constants/auth';
import { Routes } from '../../constants/routes';

export const SignedOut: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const mode = window.localStorage.getItem('eapi-logout-mode');
  const isAutoMode = mode && mode === LogOutModeType.AUTO;
  window.localStorage.clear();

  
  if (isAutoMode) {
    return (
      <Page>
        <Box textAlign="center">
          <Typography variant="overline">{t('eapi-timeout-notify-text')}</Typography>
          <Typography variant="h3">{t('eapi-signed-out-text')}</Typography>
          <Typography>{t('eapi-signed-out-info')}</Typography>
          <SignInButton className={classes.button} variant="contained">
            {t('eapi-log-back-in-text')}
          </SignInButton>
        </Box>
      </Page>
    );
  } else {
    return <Navigate to={getLocalizedRouteFromPathname(Routes.HOME, pathname)} />;
  }
};
