export enum Routes {
  // Public routes
  HOME = '/home',
  SIGNED_OUT= '/signed-out',
  NOT_FOUND = '/not-found',
  // Private routes
  SOLUTIONS = '/solutions',
  SOLUTION = '/solutions/:solutionName/:tabName',
  SUBSCRIPTIONS = '/subscriptions',
  SUBSCRIPTION = '/:clientId/:tabName',
  API_ANALYTICS = '/analytics',
  API_ACTIVITY = '/:clientId/activity',
  API_CONSUMPTION = '/:clientId/consumption',
  SWAGGER = '/subscriptions/swagger',
  GUIDES = '/guides',
  GUIDE = '/guides/:guide',
}
