import React, { FC, PropsWithChildren } from 'react';
import { useMatch } from 'react-router';
import { PathLocales } from '@internal/plugin-eapi-react/src/constants/pathLocales';
import { Routes } from '../../constants/routes';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { PageLayoutWrapper } from './PageLayoutWrapper';
import { SignUpDialog } from '../../components/SignUp/SignUpDialog';

export const PageLayout: FC<PropsWithChildren> = ({ children }) => {
  const isNotFoundMatch = useMatch(Routes.NOT_FOUND);
  const isNotFoundPtBrMatch = useMatch(`/${PathLocales.PT_BR}${Routes.NOT_FOUND}`);

  const isNotFoundRoute = isNotFoundMatch || isNotFoundPtBrMatch;

  return (
    <PageLayoutWrapper>
      <Header />
      {children}
      {!isNotFoundRoute && <Footer />}
      <SignUpDialog />
    </PageLayoutWrapper>
  );
};
