import { Language } from '@internal/plugin-eapi-common';
import { PathLocales } from '../constants/pathLocales';

export const getLocalizedRouteFromPathname = (baseRoute: string, pathname: string): string =>
  pathname.startsWith(`/${PathLocales.PT_BR}`) ? `/${PathLocales.PT_BR}${baseRoute}` : baseRoute;

export const getLocalizedRouteFromLanguage = (baseRoute: string, language?: Language): string => 
  language === Language.PT_BR ? `/${PathLocales.PT_BR}${baseRoute}` : baseRoute;

export const getLanguageFromPath = (pathname: string): Language =>
  pathname.startsWith(`/${PathLocales.PT_BR}`) ? Language.PT_BR : Language.EN_US;