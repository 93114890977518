import React, { memo, FC, ChangeEvent, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useAsync } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Select, InputLabel, FormControl, LinearProgress, MenuItem } from '@material-ui/core';
import { Subscription } from '@internal/plugin-eapi-common';
import { getLocalizedRouteFromLanguage } from '@internal/plugin-eapi-react';
import { useAuth, useLanguage, useNestedPath, useSubscriptionApi } from '../../hooks';
import { ImageMessage } from '../../components/ImageMessage';
import { Routes } from '../../constants/routes';
import { useStyles } from './styles';

export interface SubscriptionPickerProps {
  onChange: Dispatch<SetStateAction<Subscription | undefined>>;
}

export const SubscriptionPicker: FC<SubscriptionPickerProps> = memo(({ onChange }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [value, setValue] = useState<string | null>(null);
  const { clientIdParam } = useNestedPath();
  const { t } = useTranslation();
  const { language } = useLanguage();

  if (!user) {
    return <Navigate to={getLocalizedRouteFromLanguage(Routes.HOME, language)} />;
  }

  const subscriptionsApi = useSubscriptionApi();

  const {
    value: subscriptions,
    loading,
    error,
  } = useAsync(async () => {
    const activeSubscriptions = await subscriptionsApi.getSubscriptions();

    if (!activeSubscriptions.length) {
      return activeSubscriptions;
    }

    const subscription = activeSubscriptions.find((subscription) => subscription.clientId === clientIdParam) || activeSubscriptions[0];

    onChange(subscription);
    setValue(subscription.clientId);

    return activeSubscriptions;
  }, []);

  const handleSubscriptionChange = (e: ChangeEvent<{ value: unknown }>) => {
    const clientId = e.target.value as string;
    const chosenSubscription = subscriptions?.find((subscription) => subscription.clientId === clientId);

    setValue(clientId);
    chosenSubscription && onChange(chosenSubscription);
    navigate(`${clientId}`);
  };

  useEffect(() => {
    // Listening browser goback/forward button, setting the picker value and updating the subscription in the parent component
    if (!(clientIdParam && value) || clientIdParam === value) return;

    const subscription = subscriptions?.find((subscription) => subscription.clientId === clientIdParam);

    if (!subscription) return;

    setValue(subscription.clientId);
    onChange(subscription);
  }, [clientIdParam, onChange, subscriptions]);

  if (loading) {
    return <LinearProgress data-testid="linear-progress" />;
  }

  if (error) {
    return <ImageMessage type="error" title={t("eapi-subscriptions-error")} />;
  }

  if (!subscriptions || (subscriptions && subscriptions.length === 0)) {
    return <ImageMessage type="info" title={t("eapi-subscriptions-empty-state-text")} />;
  }

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor="subscriptions-select">{t("eapi-subscriptions-select-label-text")}</InputLabel>
      <Select label={t("eapi-subscriptions-select-label-text")} labelId="subscriptions-select" value={value || ''} onChange={handleSubscriptionChange}>
        {subscriptions &&
          subscriptions.map((subscription) => (
            <MenuItem value={subscription.clientId} key={subscription.clientId}>
              {subscription.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
});
