import React from 'react';
import { Card, CardMedia, CardContent, Typography, Grid } from '@material-ui/core';
import { Section } from '../../../../layout/Section';
import { APIS } from '../../../../constants/apis';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export const OurApis = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Section title={t('eapi-discover-our-apis-text')}>
      <Grid container spacing={4}>
        {APIS.map(({ id, name, description, image, webpImage, mobileImage }) => (
          <Grid item xs={12} sm={6} md={4} key={id}>
            <Card className={classes.card}>
              <CardMedia component="picture" >
                <source height={156} srcSet={webpImage} type="image/webp" media="(min-width: 601px)" />
                <source height={156} srcSet={mobileImage} type="image/webp" media="(max-width: 600px)" />
                 <img 
                 src={image} 
                 height={156} 
                 sizes='(max-width: 599) 100vw, (max-width: 900px) 50vw, (max-width: 1200px) 33vw, 360px'
                 className={classes.image} 
                 alt={name} 
                 {...{fetchpriority: "high"}}
                 />
              </CardMedia>
              <CardContent>
                <Typography variant="h5" component="h3" className={classes.cardTitle}>
                  {name}
                </Typography>
                <Typography variant="body1">{t(description)}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Section>
  );
};
