import React, { FC, PropsWithChildren } from "react";
import { useMatch } from "react-router";
import { Routes } from "../../../constants/routes";
import { PersistLogin } from "../../../components/PersistLogin";
import { Box } from "@material-ui/core";
import { PathLocales } from "@internal/plugin-eapi-react/src/constants/pathLocales";

export const PageLayoutWrapper: FC<PropsWithChildren> = ({ children }) => {
    const isSignedOutMatch = useMatch(Routes.SIGNED_OUT);
    const isSignedOutPtBrMatch = useMatch(`/${PathLocales.PT_BR}${Routes.SIGNED_OUT}`);

    const isSignedOutRoute = isSignedOutMatch || isSignedOutPtBrMatch;

    return (
        <Box display="flex" flexDirection="column" minHeight="100vh">
            {isSignedOutRoute ? <>{children}</> : <PersistLogin>{children}</PersistLogin>}
        </Box>
    )
}
