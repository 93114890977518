import { Language } from '@internal/plugin-eapi-common';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './en-US.json';
import ptTranslations from './pt-BR.json';

i18n
  .use(initReactI18next)
  .init({
    lng: Language.EN_US,
    fallbackLng: Language.EN_US,
    load: 'all',
    nonExplicitSupportedLngs: true,
    supportedLngs: [
      'en',
      'pt',
      Language.EN_US,
      Language.PT_BR
    ],
    resources: {
      [Language.EN_US]: {
        translation: enTranslations,
      },
      [Language.PT_BR]: {
        translation: ptTranslations,
      },
    },
    debug: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
