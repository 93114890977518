import React, { FC, memo } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid, FormControl, FormGroup } from '@material-ui/core';
import { APIS } from '../../../../../constants/apis';
import { SignUpFormData } from '../../constants';
import { APICardCheckbox } from '../APICardCheckbox';

interface ApisProps {
  control: Control<SignUpFormData>;
}

export const Apis: FC<ApisProps> = memo(({ control }) => {
  const { t } = useTranslation();

  return (
    <FormControl component="fieldset">
      <FormGroup>
        <Grid container spacing={4}>
          {APIS.map(({ id, name, description }) => (
            <Grid item xs={12} sm={6} md={4} key={id}>
              <APICardCheckbox control={control} id={id} name={name} description={t(description)} />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </FormControl>
  );
});

Apis.displayName = 'Apis';
